import React from "react"

export default function hero() {
  return (
    <section class="relative home-wrapper items-center overflow-hidden">
      <div class="section wf-section">
        <div
          data-w-id="688385ef-7f73-b939-b368-950b679beddc"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0em, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
          class="section-container-lg center w-container"
        >
          <div class="div-block-302"></div>
          <div class="div-block-303">
            <div class="text-base sb">1</div>
          </div>
          <h2 class="heading">
            <span class="mailroom-gradient">Sign Up</span>
          </h2>
          <h2 class="section-paragraph white ch mailroom">
            Fill out a few details about your company <br />
            and select from our list of locations.
          </h2>
        </div>
        <div
          data-w-id="d1fd53c7-8738-c6ec-28ba-1dd9d454565f"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0em, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
          class="section-container-lg center w-container"
        >
          <div class="div-block-302"></div>
          <div class="div-block-303">
            <div class="text-base sb">2</div>
          </div>
          <h2 class="heading">
            <span class="mailroom-gradient">Manage</span>
          </h2>
          <h2 class="section-paragraph white ch mailroom">
            View and manage all your physical mail and packages within the
            comfort of the Launch Turkey platform.
          </h2>
        </div>
        <div
          data-w-id="d0255e51-36a3-f188-08d7-81d67721a795"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0em, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
          class="section-container-lg center w-container"
        >
          <div class="div-block-302"></div>
          <div class="div-block-303">
            <div class="text-base sb">3</div>
          </div>
          <h2 class="heading">
            <span class="mailroom-gradient">Control</span>
          </h2>
          <h2 class="section-paragraph white ch mailroom">
            Decide to scan, forward, shred, or trash <br />
            your mail as easily as email.
          </h2>
        </div>
      </div>
    </section>
  )
}
