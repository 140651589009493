import React from "react"

export default function hero() {
  return (
    <section class="relative home-wrapper items-center overflow-hidden">
      <div className="section wf-section">
        <div className="section-container center w-container">
          <div className="section-heading-container">
            <h2 className="section-heading">
              The smartest home <br /> for all your business mail.
            </h2>
            <p className="section-p center">
              With Launch Turkey Mailroom you can easily access your mail, scan,
              forward, or trash documents, <br />
              and manage your mailing needs - all online, from any device,
              instantly.
            </p>
          </div>
        </div>
        <div className="section-container-lg w-container">
          <div className="w-layout-grid new-grid">
            <div
              id="w-node-_1270962b-ef15-da0c-2060-1b9647384743-d0daadfb"
              className="panel"
            >
              <div className="panel-txt-container justify-center">
                <div>
                  <h3 className="panel-heading">
                    A physical, legal address for your business.
                  </h3>
                  <p className="text">
                    From İstanbul to Mersin, choose from one of our convenient
                    addresses to open a bank account, engage local customers,
                    and delight investors and partners. &nbsp;
                  </p>
                </div>
              </div>
              <div className="panel-img-container">
                <img
                  src="https://res.cloudinary.com/avomeraydin/image/upload/v1676480619/Launch_Turkey_Inc._ner5ix.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 85vw, (max-width: 767px) 81vw, (max-width: 991px) 85vw, (max-width: 1279px) 44vw, 467px"
                  srcSet="https://res.cloudinary.com/avomeraydin/image/upload/v1676480619/Launch_Turkey_Inc._ner5ix.png 500w, https://res.cloudinary.com/avomeraydin/image/upload/v1676480619/Launch_Turkey_Inc._ner5ix.png 1040w"
                  alt=""
                  className="panel-img"
                />
              </div>
              <div class="w-layout-grid grid-22 m-auto">
                <img
                  src="https://assets.website-files.com/609486d2fafa37698a57db5b/61f32e6d6fc1b5e9d11f9464_fedex.svg"
                  loading="lazy"
                  alt="FedEx"
                  class="image-74"
                />
                <img
                  src="https://assets.website-files.com/609486d2fafa37698a57db5b/61f32e7fbf4e3706e690f7c4_dhl.svg"
                  loading="lazy"
                  alt="DHL"
                  class="image-74"
                />
                <img
                  src="https://assets.website-files.com/609486d2fafa37698a57db5b/61f32e9069a2e26782d23ab5_ups.svg"
                  loading="lazy"
                  alt="UPS"
                  class="image-74"
                />
                <img
                  src="https://assets.website-files.com/609486d2fafa37698a57db5b/61f32e8822fa23854abee239_usps.svg"
                  loading="lazy"
                  alt="United States Postal Service"
                  class="image-74"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
