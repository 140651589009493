/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, title, children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <>
      <title>{defaultTitle ? `${title} | ${defaultTitle}` : title}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.siteMetadata?.author || ``} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <link
      rel="icon"
      href="https://res.cloudinary.com/avomeraydin/image/upload/v1665496139/assets/images/logo_ltr_bwfz3z.svg"
      type="image/png"
      />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <link rel="icon" href="https://res.cloudinary.com/avomeraydin/image/upload/v1665496139/assets/images/logo_ltr_bwfz3z.svg" />

      <link href="https://res.cloudinary.com/avomeraydin/raw/upload/v1665401673/assets/libs/animate.min_f3qmfv.css" rel="stylesheet" />
      <link href="https://res.cloudinary.com/avomeraydin/raw/upload/v1665401701/assets/libs/tobii.min_wzxuaw.css" rel="stylesheet" />
      <link href="https://res.cloudinary.com/avomeraydin/raw/upload/v1665401731/assets/libs/tiny-slider_sz137n.css" rel="stylesheet" />
      <link href="https://res.cloudinary.com/avomeraydin/raw/upload/v1665401759/assets/libs/line_d0cziz.css" type="text/css" rel="stylesheet" />
      <link rel="stylesheet" href="https://res.cloudinary.com/avomeraydin/raw/upload/v1665401606/assets/css/icons.min_zgjpya.css" />
      <link rel="stylesheet" href="https://res.cloudinary.com/avomeraydin/raw/upload/v1665401604/assets/css/tailwind_pxarfd.css" />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css" integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A==" crossorigin="anonymous" referrerpolicy="no-referrer" />
      {children}
    </>
  )
}

Seo.defaultProps = {
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default Seo
