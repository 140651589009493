import React from "react"

export default function hero() {
  return (
    <section class="relative home-wrapper items-center overflow-hidden">
      <div class="section us wf-section">
        <div class="section-container w-container">
          <div class="flex-container-vertical-center">
            <div class="div-block-402">
              <div class="text-block-106">Launch Turkey Mailroom</div>
            </div>
            <h1 class="heading _22ch">
              Get a great business address and better virtual mailbox. <br />
            </h1>
            <p class="lp-text">
              Manage your business with a unique, physical Turkiye address, and
              a seamless virtual mailbox, accessible anywhere, anytime.{" "}
              <span class="gray">
                <br />
              </span>
            </p>
            <div class="div-block-403">
              <div class="gradient-btn">
                <div class="gradient--btn mailroom">
                  <a
                    href="https://dashboard.launchturkey.com/"
                    target="_blank"
                    class="gradient-button-blue w-button"
                  >
                    Get Mailroom™
                  </a>
                </div>
              </div>
              <a href="/pricing" class="secondary-btn w-button">
                See pricing
              </a>
            </div>
            <img
              src="https://assets.website-files.com/609486d2fafa37698a57db5b/6298299dc142614b15c9a84e_mailroom-img%20(1).png"
              loading="eager"
              sizes="(max-width: 479px) 92vw, (max-width: 991px) 90vw, 700px"
              srcset="https://assets.website-files.com/609486d2fafa37698a57db5b/6298299dc142614b15c9a84e_mailroom-img%20(1)-p-500.png 500w, https://assets.website-files.com/609486d2fafa37698a57db5b/6298299dc142614b15c9a84e_mailroom-img%20(1)-p-800.png 800w, https://assets.website-files.com/609486d2fafa37698a57db5b/6298299dc142614b15c9a84e_mailroom-img%20(1)-p-1080.png 1080w, https://assets.website-files.com/609486d2fafa37698a57db5b/6298299dc142614b15c9a84e_mailroom-img%20(1)-p-1600.png 1600w, https://assets.website-files.com/609486d2fafa37698a57db5b/6298299dc142614b15c9a84e_mailroom-img%20(1).png 2644w"
              alt="Launch Turkey Mailroom™"
              class="product-hero-img mailroom"
            />
          </div>
        </div>
        <img
          src="https://assets.website-files.com/609486d2fafa37698a57db5b/6296864622fd5d5ad09d7990_mailroom-bg.svg"
          loading="lazy"
          alt=""
          class="hero-bg"
        />
      </div>
    </section>
  )
}
